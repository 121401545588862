import { BiSolidCopy } from "react-icons/bi"
import "../../styles/Profile.css"
import { IoIosCheckmarkCircle } from "react-icons/io"

import axios from 'axios';
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setProfileData } from "../../redux/walletSlice";
import UserContext from '../../context/UserContext';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Profile = () => {
    const dispatch = useDispatch();

    const userData = useSelector((state) => state.wallet.userData);
    const profileData = useSelector((state) => state.wallet.profileData);

    const configData = useSelector((state) => state.wallet.configData);
    const referralData = useSelector((state) => state.wallet.referralData);
    const topSharePowerHolders = useSelector((state) => state.wallet.topSharePowerHolders);

    const { walletAddress, syncWallet, unsyncWallet, setWeb3Prop } = useContext(UserContext);

    // Get the base URL from environment variables
    const baseUrl = process.env.REACT_APP_BASE_URL || 'https://example.com/referral';

    // Create the full referral link using userData.reflink
    const referralLink = `${baseUrl}?code=${userData.reflink}`;

    const [formData, setFormData] = useState({
        wallet_address: walletAddress,
        fullname: "",
        display_name: "",
        profile: "",
        email: "",
        phone: "",
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.fullname.trim()) formErrors.fullname = "Full name is required.";
        if (!formData.display_name.trim()) formErrors.display_name = "Display name is required.";
        if (!formData.profile) formErrors.profile = "Profile type is required.";
        if (!formData.email) formErrors.email = "Email is required.";
        if (!formData.phone) formErrors.phone = "Phone number is required.";
        return formErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        setErrors({});
        setLoading(true);

        try {
            // Make sure formData is the object you want to send as JSON
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}profile/${walletAddress}`,
                formData, // Pass formData as the body of the POST request
                {
                    headers: {
                        'Content-Type': 'application/json', // Ensure the content type is set to JSON
                    },
                }
            );
            
            if (response.data) {
                dispatch(setProfileData(response.data)); // Dispatch the profile data to the state
            }
            
            toast.success("Profile saved successfully!"); // Show success toast
            setLoading(false); // Stop loading spinner or state
        } catch (error) {
            console.error("Error saving profile:", error);
            toast.error("Error saving profile: "+ error); // Show error toast
            setLoading(false); // Stop loading spinner or state
        }
    };

    // Assuming you fetch the profile data on component mount
    useEffect(() => {
        // Simulating fetching profile data
        const fetchProfile = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}profile/${walletAddress}`);
                console.log(response.data)
                if (response.data) {
                    dispatch(setProfileData(response.data));
                }
            } catch (error) {
                console.error('Error fetching overview data:', error);
            }
        };

        fetchProfile();
    }, []);


    return <>
        <h2 className="profile">Profile</h2>

        <div className="profileWrapper">
            <div className="summaryWrapper">
                <h4>Summary</h4>
                <div className="summaryCards">
                    <div className="sumCard">
                        <div className="cardTitle">
                            <p>Pool Prize <span>+50%</span></p>
                        </div>
                        <h2>&#8358;{Number(configData?.prize_pool).toLocaleString()}</h2>
                    </div>

                    <div className="sumCard">
                        <div className="cardTitle">
                            <p>LBT Token</p>
                        </div>
                        <h2>{parseFloat(userData?.lbt).toFixed(5)} <small>LBT</small></h2>
                    </div>

                    <div className="sumCard">
                        <div className="cardTitle">
                            <p>Share Power</p>
                        </div>
                        <h2>
                        {userData?.share_power}
                            <img src={require("../../assets/images/flash.png")} alt="" />
                        </h2>
                    </div>

                    <div className="sumCard">
                        <div className="cardTitle">
                            <p>Total Referrals</p>
                        </div>
                        <h2>
                        {userData?.direct_referrals}
                        </h2>
                    </div>

                </div>


                <div className="referralLink">
                    <p><b>Referral Link:</b> {referralLink}</p>
                    <CopyToClipboard text={referralLink} onCopy={() => toast.success('Referral link copied to clipboard!')}>
                        <button>
                            <BiSolidCopy />
                        </button>
                    </CopyToClipboard>
                </div>

                


            </div>

            <div className="userInformation">
                <h4>User Information</h4>

                <div className="userInfoWrapper">
                    <div className="profileStatus">
                        <p>Profile Status</p>

                        {userData.paid ? (
                            profileData?.id ? (
                                <div className="profileProgress">
            
                                    <span>Complete</span>
                                    <div className="progressBar">
                                        <div className="progressLoad" style={{ width: "100%", backgroundColor: "green" }}>100%</div>
                                    </div>
                                </div>

                            ) : (
                                <div className="profileProgress">
                                    <span>Complete</span>
                                    <div className="progressBar">
                                        <div className="progressLoad" style={{ width: "50%", backgroundColor: "yellow" }}>50%</div>
                                    </div>
                                </div>
                            )
                        ) : (
                            <div className="profileProgress">
                                    <span>Complete</span>
                                    <div className="progressBar">
                                        <div className="progressLoad" style={{ width: "0%", backgroundColor: "red" }}>0%</div>
                                    </div>
                                </div>
                        )}
                    </div>
                    <>
                        {userData.paid ? (
                            profileData?.id ? (
                                // Show the profile details if profile exists
                                <>
                                <p>Fullname: {profileData.fullname}</p>
                                    <p>Display Name: {profileData.display_name}</p>
                                    <p>Profile Type: {profileData.profile}</p>
                                    <p>Email Address: {profileData.email}</p>
                                    <p>Phone Number: {profileData.phone}</p>
                                </>
                            ) : (
                                // Show the form if profile is empty
                                <form onSubmit={handleSubmit}>
                                    <div className="inputWrapper">
                                        <label htmlFor="fullname">Full Name</label>
                                        <input
                                            type="text"
                                            name="fullname"
                                            placeholder="Enter your full name"
                                            value={formData.fullname}
                                            onChange={handleChange}
                                        />
                                        {errors.fullname && <p className="error">{errors.fullname}</p>}
                                    </div>
                                    <div className="inputWrapper">
                                        <label htmlFor="display_name">Display Name</label>
                                        <input
                                            type="text"
                                            name="display_name"
                                            placeholder="Enter your display name"
                                            value={formData.display_name}
                                            onChange={handleChange}
                                        />
                                        {errors.display_name && <p className="error">{errors.display_name}</p>}
                                    </div>
                                    <div className="inputWrapper">
                                        <label htmlFor="profile">Profile Type</label>
                                        <select
                                            name="profile"
                                            value={formData.profile}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select a profile type</option>
                                            <option value="Community">Community Member</option>
                                            <option value="Gamer">Gamer</option>
                                            <option value="Mentee">Mentee</option>

                                        </select>
                                        {errors.profile && <p className="error">{errors.profile}</p>}
                                    </div>
                                    <div className="inputWrapper">
                                        <label htmlFor="email">Email Address</label>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Enter an email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                        {errors.email && <p className="error">{errors.email}</p>}
                                    </div>
                                    <div className="inputWrapper">
                                        <label htmlFor="phone">Phone Number</label>
                                        <input
                                            type="text"
                                            name="phone"
                                            placeholder="Enter a phone number"
                                            value={formData.phone}
                                            onChange={handleChange}
                                        />
                                        {errors.phone && <p className="error">{errors.phone}</p>}
                                    </div>
                                    <button type="submit" disabled={loading}>
                                        {loading ? "Saving..." : "Save"}
                                    </button>
                                </form>
                            )
                        ) : (
                            <>
                            
                            <p className="buySteps">You need to buy a boost before you can update your profile.
                            Click below and select your preferred boost line. Your boost line determines how many SP you get and how it will increase when you refer. </p>
                            <p></p>
                            <br/>
                            <Link to="/dashboard/transactions">
                                <button>
                                    Buy Boost
                                </button>
                            </Link>
                            </>
                        )}
                    </>
                </div>
            </div>
        </div>




        <div className="powerHolderWrapper">
            <h3>Top Share Power Holder</h3>

            <div className="powerHolders">
                <div className="powerUsersContainer">
                    {topSharePowerHolders && topSharePowerHolders.length > 0 ? (
                        <div className="powerUsersWrapper">
                            {topSharePowerHolders.map((user, index) => (
                                <div className="users" key={user.walletAddress}>
                                    <img
                                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                                            user.display_name || user.wallet_address // Use display_name if available, otherwise wallet_address
                                        )}&background=FFC81E&color=fff`} // Generates avatar with yellow background (#FFC81E)
                                        alt={`${user.display_name || user.wallet_address} avatar`}
                                    />
                                    <span className="position">
                                        {index + 1}
                                        {index === 0 ? "st" : index === 1 ? "nd" : index === 2 ? "rd" : "th"}
                                    </span>
                                    <h3>{user.display_name
                                            ? `${user.display_name.substring(0, 9)}${user.display_name.length > 9 ? '...' : ''}`
                                            : `${user.wallet_address.substring(0, 6)}...`}</h3> {/* Display display_name if available */}
                                    
                                    <p>
                                        {user.share_power}{" "}
                                        <img
                                            src={require("../../assets/images/yellowflash.png")}
                                            alt="flash icon"
                                        />
                                    </p>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p>No data available</p> // Display message when no data exists
                    )}
                </div>
            </div>
        </div>
    </>

}

export default Profile