// src/redux/walletOverviewSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const walletOverviewSlice = createSlice({
  name: 'walletOverview',
  initialState: {
    walletAddress: null,  // Stores the wallet address
    isAuthenticated: false,  // Checks if user is authenticated (wallet connected)
    userData:{
      "wallet_address": "",
      "lbt": "0.00000",
      "share_power": "0",
      "direct_referrals": 0,
      "indirect_referrals": 0,
      "community": false,
      "reflink": "",
      "created_at": ""
    },  // Stores the user data fetched from the backend
    configData: {prize_pool:0, bnb_rate:0},  // Stores config data fetched from the backend
    transactionData: [],
    profileData: [],
    topSharePowerHolders: [],  // Stores top share power holders
    referralData:{direct:[], indirect:[]},
    socialTasks: [],  // Stores social tasks associated with the user
  },
  reducers: {
    setWalletAddress: (state, action) => {
      state.walletAddress = action.payload.walletAddress; // Set wallet address
      state.isAuthenticated = true; // Set authentication to true when wallet is connected
    },
    setOverviewData: (state, action) => {
      state.userData = action.payload.user;
      state.configData = action.payload.config;
      state.socialTasks = action.payload.socialTasks; 
      state.topSharePowerHolders = action.payload.topSharePowerHolders;
    },
    setUserData: (state, action) => {
      state.userData = action.payload.userData; // Set user data from backend
    },
    setProfileData: (state, action) => {
      state.profileData = action.payload; // Set user data from backend
    },
    setSocialData: (state, action) => {
      state.socialTasks = action.payload;  // Set user data from backend
    },
    setReferralData: (state, action) => {
      state.referralData = action.payload;  // Set user data from backend
    },
    setConfigData: (state, action) => {
      state.configData = action.payload; // Set configuration data
    },
    setBnbRate: (state, action) => {
      state.configData = {
        ...state.configData, // Preserve existing properties in configData
        bnb_rate: action.payload, // Only update the bnb_rate
      };
    },
    setTransactionData: (state, action) => {
      state.transactionData = action.payload; // Set configuration data
    },
    setTopSharePowerHolders: (state, action) => {
      state.topSharePowerHolders = action.payload; // Set top share power holders
    },
    disconnectWallet: (state) => {
      state.walletAddress = null; // Clear wallet address
      state.isAuthenticated = false; // Set authentication to false when wallet is disconnected
      state.userData = null; // Clear user data
      state.configData = null; // Clear config data
      state.socialTasks = [];  // Clear social tasks
      state.topSharePowerHolders = []; // Clear top share power holders
    },
  },
});

export const {
  setWalletAddress,
  setBnbRate,
  setProfileData,
  setConfigData,
  setReferralData,
  setSocialData,
  setOverviewData,
  setTransactionData,
  setTopSharePowerHolders,
  disconnectWallet,
} = walletOverviewSlice.actions;

export default walletOverviewSlice.reducer;
