// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// ProtectedRoute component will handle the route protection logic
const ProtectedRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.wallet.isAuthenticated);

  // If not authenticated, redirect to login
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return children; // If authenticated, render the child components
};

export default ProtectedRoute;
