import React, { createContext, useState, useEffect } from 'react';
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import axios from "axios";
import Web3Modal from "web3modal";
import { Link, useNavigate } from 'react-router-dom';

import { setWalletAddress, disconnectWallet } from '../redux/walletSlice';
import { useDispatch } from 'react-redux';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [web3, setWeb3] = useState(null);
  const [provider, setProvider] = useState(null);

  const [walletAddress, setAddress] = useState(() => {
    // Retrieve from localStorage, or null if not available
    return localStorage.getItem('walletAddress') || null;
  });

 


  // On initial load, check if there is a wallet address stored in localStorage
  useEffect(() => {
    const storedWallet = localStorage.getItem('walletAddress');
    if (storedWallet) {
      setAddress(storedWallet);
      // Update Redux state
      dispatch(setWalletAddress(walletAddress));

      configWeb3Provider();

      // Redirect to the dashboard after successful connection
      navigate('/dashboard');
    }
  }, []);

  const configWeb3Provider = async () => {
    try {
      // Configure Web3Modal with providers for MetaMask, Trust Wallet, etc.
      if (Web3Modal.cachedProvider) {
        const provider = await Web3Modal.connect();
        setProvider(provider);
        const web3Instance = new Web3(provider);
        setWeb3(web3Instance);
      }

   

      
    } catch (error) {
      console.error("Wallet connection failed:", error);
    }
  };

  const clearWeb3Provider = async () => {
    try {
      // Configure Web3Modal with providers for MetaMask, Trust Wallet, etc.
      if (Web3Modal.cachedProvider) {
        const provider = await Web3Modal.connect();
        provider.clearCachedProvider();
      }

   

      
    } catch (error) {
      console.error("Wallet connection failed:", error);
    }
  };

  const syncWallet = (address) => {
    
    setAddress(address);
    localStorage.setItem('walletAddress', address); // Persist wallet connection
  };

  const setWeb3Prop = (provider,web3) => {
    setProvider(provider);
    setWeb3(web3);
  };

  const unsyncWallet = async() => {
    setAddress(null);
    await clearWeb3Provider;
    localStorage.removeItem('walletAddress'); // Clear wallet connection
    localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER')
  };

  return (
    <UserContext.Provider value={{ walletAddress, setWeb3Prop, syncWallet, unsyncWallet }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
