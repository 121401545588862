
import './App.css';
import {useNavigate,BrowserRouter as Router, Routes, Route} from "react-router-dom"
import { useSelector } from 'react-redux';
import Landing from './pages/Landing';
import Layout from './pages/dashboard/Layout';
import Overview from './pages/dashboard/Overview';
import Transaction from './pages/dashboard/Transaction';
import Referral from './pages/dashboard/Referral';
import Profile from './pages/dashboard/Profile';
import ProtectedRoute from './ProtectedRoute'; // The ProtectedRoute component
import { UserProvider } from './context/UserContext'; // Import the UserProvider
import { ToastContainer } from 'react-toastify';


const App = () => {
 


  return (
    <UserProvider>
      <ToastContainer />
    <Routes>
      <Route path="/" element={<Landing />} />
     
      {/* Protect the Dashboard route */}
      <Route 
        path="/dashboard" 
        element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        } 
      >
    
      <Route index element={<Overview />} />
      <Route path="transactions" element={<Transaction />} />
      <Route path="referral" element={<Referral />} />
      <Route path="profile" element={<Profile />} />
    </Route>
    </Routes>
    </UserProvider>
  );
};

export default App;

