import { SiAuthelia } from "react-icons/si"
import Navbar from "../components/Navbar"
import "../styles/Landing.css"
import { FaAward, FaFacebook, FaInstagram, FaQuestion, FaTelegramPlane, FaYoutube } from "react-icons/fa"
import { BsFillHouseFill } from "react-icons/bs"
import { FaArrowTrendUp, FaXTwitter } from "react-icons/fa6"
import { useNavigate } from "react-router"
import { MdOutlineArrowCircleLeft, MdOutlineArrowCircleRight } from "react-icons/md"
import { RxActivityLog } from "react-icons/rx"

import { useContext, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';



import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import axios from "axios";
import Web3Modal from "web3modal";
import UserContext from '../context/UserContext';
import { setWalletAddress, disconnectWallet } from '../redux/walletSlice';

const Landing = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch();
  

 

    //const [userWallet, setUserWallet] = useState(null);
    const { walletAddress, syncWallet, unsyncWallet, setWeb3Prop } = useContext(UserContext);

    const connectWallet = async () => {
        try {
          // Configure Web3Modal with providers for MetaMask, Trust Wallet, etc.
          const providerOptions = {
            injected: {
              display: {
                name: "MetaMask",
                description: "Connect with MetaMask in your browser"
              },
              package: null // This will be used to detect injected providers like MetaMask
            },
            walletconnect: {
                package: WalletConnectProvider, // Required package for WalletConnect
                options: {
                  rpc: {
                    56: "https://bsc-dataseed.binance.org/", // BSC Mainnet RPC URL
                  },
                  network: "binance-mainnet", // Optional - Helps identify the network
                },
              },
              
              trustwallet: {
                package: WalletConnectProvider, // Required package for Trust Wallet as it uses WalletConnect
                options: {
                  rpc: {
                    56: "https://bsc-dataseed.binance.org/", // BSC Mainnet RPC URL
                  },
                  network: "binance-mainnet", // Optional - Helps identify the network
                },
              },
          };
    
          // Initialize Web3Modal
          const web3Modal = new Web3Modal({
            cacheProvider: false, // Optional - Enable if you want to cache the provider
            providerOptions, // Required
          });
    
          // Connect wallet
          const provider = await web3Modal.connect();
          const web3Instance = new Web3(provider);
    
    
          setWeb3Prop(provider, web3Instance)
    
          // Get user's wallet address
          const accounts = await web3Instance.eth.getAccounts();
          const walletAddress = accounts[0];
    
    
    
          
    
          // You can handle account creation or login here 
          // Check if user exists or create a new account
          handleUserAccount(walletAddress);
          
        } catch (error) {
          handleDisconnect();
          console.error("Wallet connection failed:", error);
        }
      };
    
    const handleUserAccount = async (walletAddress) => {
        const referralId = localStorage.getItem('referralId') || null;
    try {
        // Call your backend API to check if the user exists or create a new account
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}user/login-or-create`, {
        walletAddress: walletAddress,
        reflink: referralId // Include referral ID if available
        });
        
        if (response.data.id) {

        console.log("User logged in or created successfully:", response.data);

        // Dispatch the wallet address and user data to the Redux store
        dispatch(setWalletAddress({
            walletAddress: walletAddress,
            userData: {
            id: response.data.id,
            lbt: response.data.lbt,
            sharePower: response.data.share_power,
            directReferrals: response.data.direct_referrals,
            indirectReferrals: response.data.indirect_referrals,
            community: response.data.community,
            reflink: response.data.reflink,
            createdAt: response.data.created_at,
            },
        }));

        syncWallet(walletAddress);
        // Update Redux state
        dispatch(setWalletAddress(walletAddress));
        // Redirect to the dashboard after successful connection
        navigate('/dashboard');

        } else {
        console.error("Failed to log in or create user");
        handleDisconnect();
        navigate('/');
        }
    } catch (error) {
        handleDisconnect();
        console.error("Error with user account handling:", error);
    }
    };

    // Handle wallet disconnection
    const handleDisconnect = () => {
        unsyncWallet();
        // Update Redux state
        dispatch(disconnectWallet());
    };

    return<>
        <Navbar/>
        <div className="body">
            <div className="hero">
                <div className="heroOverlay">
                    <h1>THE BIGGEST BLOCKCHAIN SHOW </h1>
                    <p>Compete, Share, and Cash in - where every challenge brings you closer to a life-changing fortune as a viewer or a housemate. Sign-up to 5,000X your Share Power ⚡SP for an unlimited earning opportunities.</p>
                    <div className="buttonWrap">
                        <button onClick={connectWallet}><SiAuthelia /> Sign-up</button>
                    
                    </div>
                </div>

                
                {/* <div className="cryptoWrap">
                    <MdOutlineArrowCircleLeft className="arrow" />
                    <div className="crypto">
                        <div className="cryptoCard">
                            <p>BTC</p>
                            <h3>$61,000</h3>
                            <span><FaArrowTrendUp /> +2.14%</span>
                        </div>
                        <div className="cryptoCard">
                            <p>BTC</p>
                            <h3>$61,000</h3>
                            <span><FaArrowTrendUp /> +2.14%</span>
                        </div>
                        <div className="cryptoCard">
                            <p>BTC</p>
                            <h3>$61,000</h3>
                            <span><FaArrowTrendUp /> +2.14%</span>
                        </div>
                        <div className="cryptoCard">
                            <p>BTC</p>
                            <h3>$61,000</h3>
                            <span><FaArrowTrendUp /> +2.14%</span>
                        </div>
                        <div className="cryptoCard">
                            <p>BTC</p>
                            <h3>$61,000</h3>
                            <span><FaArrowTrendUp /> +2.14%</span>
                        </div>
                        <div className="cryptoCard">
                            <p>BTC</p>
                            <h3>$61,000</h3>
                            <span><FaArrowTrendUp /> +2.14%</span>
                        </div>
                        <div className="cryptoCard">
                            <p>BTC</p>
                            <h3>$61,000</h3>
                            <span><FaArrowTrendUp /> +2.14%</span>
                        </div>
                    </div>
                    <MdOutlineArrowCircleRight  className="arrow" />
                </div> */}
            </div>


            <div className="cardsWrapper">
                <div className="card">
                <BsFillHouseFill />
                    <h3>One Mansion </h3>
                    <p>16 Housemates, 60 days. Where eSports entertainment meets the blockchain ecosystem.</p>
                </div>
                <div className="card">
                <img src={require("../assets/images/Lamba coin transparent  image copy.png")} alt="" />
                    <h3>Limited Token ($LBT) </h3>
                    <p>Accumulate a very limited digital asset with total supply of 4.5million for global engagement and drive.</p>
                </div>
                <div className="card">
                <FaAward />
                    <h3>Share from Prize Pool </h3>
                    <p>With your Share Power (⚡SP), you can share from prize pools of multiple tournaments without a reduction in total amount of the ⚡SP </p>
                </div>
                <div className="card">
                <RxActivityLog />
                    <h3>Regular Activities</h3>
                    <p>Potential daily earning opportunities with your Share Power⚡. 50% of all Prize Pool goes to the community based on Share Power⚡</p>
                </div>
            </div>
            <p className="accumulate">Accumulate free Lambatoken ($LBT) and boost your Share Power to keep earning <a href="#" onClick={connectWallet} className="buttonLink">here</a></p>

            <div className="demoWrapper">
                <h3>TheGenesis Reality Show</h3>
                <div className="demoCards">
                    <div className="demoCard">
                        <img src={require("../assets/images/CELEB EVENTS.png")} alt="" />
                        <p>Previous Events</p>
                    </div>
                    <div className="demoCard">
                        <img src={require("../assets/images/CELEB EVENTS (3).png")} alt="" />
                        <p>Upcoming Event</p>
                    </div>
                    <div className="demoCard">
                        <img src={require("../assets/images/Home Page_1.png")} alt="" />
                        <p>Place a Share</p>
                    </div>
                    <div className="demoCard">
                        <img src={require("../assets/images/Wallet Page_1.png")} alt="" />
                        <p>Lambatoken Wallet</p>
                    </div>
                </div>
            </div>

            <p className="accumulate2">Accumulate free Lambatoken ($LBT) and boost your Share Power for an unending income stream from prize pool rewards   <a href="#" onClick={connectWallet} className="buttonLink">here</a></p>
            

            <div className="bottomHero">
            <img className="moon" src={require("../assets/images/moon-1.png")} alt="" />
                <div className="bottomHeroOverlay">

                    <div className="bottomCards">
                        <h2>ROAD MAP</h2>
                        <div className="bottomCardsWrapper">
                            <div className="bottomCard">
                                <h3>January 2025</h3>
                                <p> Airdrop and Share Power ⚡SP Platform Launch</p>
                            </div>
                            <div className="bottomCard">
                                <h3>January and February 2025</h3>
                                <p>Tier2 Housemate Selection Process Starts.</p>
                            </div>
                            <div className="bottomCard">
                                <h3>March 2025</h3>
                                <p>Airdrop Distribution and Event </p>
                            </div>
                            <div className="bottomCard">
                                <h3>May 2025 </h3>
                                <p>The premier blockchain and eSports reality show.</p>
                            </div>
                        </div>
                    </div>

                    <div className="questions">
                        <h2>OUR PARTNERS</h2>
                        <div className="partners">
                            <img src={require("../assets/images/Krypto Extract.png")} alt="" />
                            <img src={require("../assets/images/IMG_2474.png")} alt="" />
                            <img src={require("../assets/images/logo (1).png")} alt="" />
                            <img src={require("../assets/images/Lambabros Logo Black Background.png")} alt="" />
                            <img src={require("../assets/images/TheGather, V-01.png")} alt="" />
                        </div>
                    </div>

                    <div className="sponsors">
                        <h1>SPONSORS AND PARTNERS</h1>
                        <p>Want to become a Sponsor or Partner? Join us today and elevate your brand!
                    </p>
                    <button onClick={() => window.open('https://wa.link/ibmh53', '_blank')}>Connect With Us</button>
                    </div>

                    <div className="connect">
                        <h3>Connect socially with Us</h3>

                        <div className="connectCardWrapper">
                            <div className="connectCard" onClick={() => window.open("https://www.instagram.com/houseoflambabros?igsh=MXBldHFmN2h1MXFiaA==", "_blank")}>
                            <FaInstagram />
                                <p>Instagram</p>
                            </div>
                            <div className="connectCard"  onClick={() => window.open("https://www.youtube.com/@officialHOLTv", "_blank")}>
                            <FaYoutube />
                                <p>Youtube</p>
                            </div>
                            <div className="connectCard"  onClick={() => window.open("https://t.me/houseoflambabros", "_blank")}>
                            <FaTelegramPlane />
                                <p>Telegram</p>
                            </div>
                            <div className="connectCard"  onClick={() => window.open("https://x.com/Hoflambabros?t=4142jUuhSGo_OKvu24KMfQ&s=09", "_blank")}>
                            <FaXTwitter />
                                <p>X</p>
                            </div>
                        </div>
                    </div>


                    <footer>
                        <div className="leftFooter">
                            <small>2024 &copy;. lambatoken rights reserved.</small>
                        </div>
                        <div className="rightFooter">
                            <small>Privacy Policy</small>
                            <small>Terms & conditions</small>
                        </div>
                    </footer>
                </div>
            </div>
        </div>


    </>

}

export default Landing