import { Outlet } from "react-router"
import Sidebar from "../../components/Sidebar"

import { useSelector } from 'react-redux';

const Layout = () => {
  // Access the user data from Redux state
  const userData = useSelector((state) => state.wallet.userData);
  const walletBalance = userData?.lbt ?? '0.00000'; // Fallback balance if userData or lbt is not available

  return (
    <>
      <Sidebar />
      <main>
        <div className="walletBlc">
          <div className="walletBrief">
            <img src={require('../../assets/images/Wallet Coin.png')} alt="Wallet Icon" />
            <span>Wallet balance</span>
            {/* Display the wallet balance from Redux */}
            <p>{parseFloat(walletBalance).toFixed(5)} <small>LBT</small></p>
          </div>
        </div>
        <Outlet />
      </main>
    </>
  );
};


export default Layout