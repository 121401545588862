import { IoMdArrowDropdown, IoMdPerson } from "react-icons/io"
import "../styles/Navbar.css"
import { useContext, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';



import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import axios from "axios";
import Web3Modal from "web3modal";
import UserContext from '../context/UserContext';
import { setWalletAddress, disconnectWallet } from '../redux/walletSlice';

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [scrolled, setScrolled] = useState(false);
  const [showLogin, setShowlogin] = useState(false)
  const [showRegister, setShowRegister] = useState(false)

  //const [userWallet, setUserWallet] = useState(null);
  const { walletAddress, syncWallet, unsyncWallet, setWeb3Prop } = useContext(UserContext);




  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 50) { // Adjust the value based on when you want the navbar to change
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    // Call this function on app load
    storeReferralInfo();
  }, []);


  // Check for 'ref' parameter in the URL and store it in localStorage
  const storeReferralInfo = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get('ref');
    if (ref) {
      localStorage.setItem('referralId', ref); // Save referral ID in localStorage
    }
  }




  const connectWallet = async () => {
    try {
      // Configure Web3Modal with providers for MetaMask, Trust Wallet, etc.
      const providerOptions = {
        injected: {
          display: {
            name: "MetaMask",
            description: "Connect with MetaMask in your browser"
          },
          package: null // This will be used to detect injected providers like MetaMask
        },
        walletconnect: {
          package: WalletConnectProvider, // Required package for WalletConnect
          options: {
            rpc: {
              56: "https://bsc-dataseed.binance.org/", // BSC Mainnet RPC URL
            },
            network: "binance-mainnet", // Optional - Helps identify the network
          },
        },
        
        trustwallet: {
          package: WalletConnectProvider, // Required package for Trust Wallet as it uses WalletConnect
          options: {
            rpc: {
              56: "https://bsc-dataseed.binance.org/", // BSC Mainnet RPC URL
            },
            network: "binance-mainnet", // Optional - Helps identify the network
          },
        },
      };

      // Initialize Web3Modal
      const web3Modal = new Web3Modal({
        cacheProvider: false, // Optional - Enable if you want to cache the provider
        providerOptions, // Required
      });

      // Connect wallet
      const provider = await web3Modal.connect();
      const web3Instance = new Web3(provider);


      setWeb3Prop(provider, web3Instance)

      // Get user's wallet address
      const accounts = await web3Instance.eth.getAccounts();
      const walletAddress = accounts[0];



      

      // You can handle account creation or login here 
      // Check if user exists or create a new account
      handleUserAccount(walletAddress);
      
    } catch (error) {
      handleDisconnect();
      console.error("Wallet connection failed:", error);
    }
  };

  const handleUserAccount = async (walletAddress) => {
    const referralId = localStorage.getItem('referralId') || null;
    try {
      // Call your backend API to check if the user exists or create a new account
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}user/login-or-create`, {
        walletAddress: walletAddress,
        reflink: referralId // Include referral ID if available
      });
      
      if (response.data.id) {

        console.log("User logged in or created successfully:", response.data);

        // Dispatch the wallet address and user data to the Redux store
        dispatch(setWalletAddress({
          walletAddress: walletAddress,
          userData: {
            id: response.data.id,
            lbt: response.data.lbt,
            sharePower: response.data.share_power,
            directReferrals: response.data.direct_referrals,
            indirectReferrals: response.data.indirect_referrals,
            community: response.data.community,
            reflink: response.data.reflink,
            createdAt: response.data.created_at,
          },
        }));

        syncWallet(walletAddress);
        // Update Redux state
        dispatch(setWalletAddress(walletAddress));
        // Redirect to the dashboard after successful connection
        navigate('/dashboard');

      } else {
        console.error("Failed to log in or create user");
        handleDisconnect();
        navigate('/');
      }
    } catch (error) {
      handleDisconnect();
      console.error("Error with user account handling:", error);
    }
  };

  // Handle wallet disconnection
  const handleDisconnect = () => {
    unsyncWallet();
    // Update Redux state
    dispatch(disconnectWallet());
  };

  return <>
    <nav className={scrolled ? "navScrolled" : undefined}>
      <div className="logo">
        <img src={require("../assets/images/dgenesis-logo.png")} alt="" />
      </div>

      <div className="menu">
        {walletAddress ? (

          <div className="userName">
            <h3>{walletAddress.substring(0, 12)} {walletAddress.length >= 9 && '...'} <span></span></h3>

          </div>
        ) : (
          <button onClick={connectWallet}>Connect Wallet</button>
        )}
        {walletAddress ? (
          <div className="account" onMouseOver={() => setShowlogin(true)} onClick={() => setShowlogin(!showLogin)}>
            <IoMdPerson />
            <IoMdArrowDropdown />

            <div className={showLogin ? "accountLogin" : "hide"} onMouseLeave={() => setShowlogin(false)}>
              <span className="triangle"></span>

              <div className="navigations">
                <Link
                  to={"/dashboard"}
                >

                  Dashboard
                </Link>
              </div>


              <button onClick={handleDisconnect}>Logout</button>


            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>

    </nav>


  </>

}

export default Navbar